@tailwind base;
@tailwind components;
@tailwind utilities;

text {
    font-family: "GeneralSans", sans-serif;
}

#detail-bar-chart .tick line {
    visibility: hidden;
}

.no-scrollbar::-webkit-scrollbar {
    display: none;
}

.no-scrollbar {
    -ms-overflow-style: none;
    /* IE and Edge */
    scrollbar-width: none;
    /* Firefox */
}

.link-underline-white {
    box-shadow: inset 0 -0.1em 0 0 theme("colors.white")
}

.link-underline-blue {
    box-shadow: inset 0 -0.1em 0 0 theme("colors.blue");
    color: theme("colors.blue")
}

.text-highlight-white {
    box-shadow: inset 0 -1.2em 0 0 theme("colors.white");
    color: theme("colors.blue");
}

.text-highlight-blue {
    box-shadow: inset 0 -1.2em 0 0 theme("colors.blue");
    color: theme("colors.white");
}

.side-gradient {
    background: linear-gradient(90deg, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 0) 3%, rgba(255, 255, 255, 0) 97%, rgba(255, 255, 255, 1) 100%);
}

.fill-button {
    position: relative;
    overflow: hidden;
    background: linear-gradient(45deg, theme("colors.blue") 50%, theme("colors.white") 50%);
    -webkit-text-fill-color: transparent;
}

.fill-button svg {
    fill: theme("colors.blue");
    transition: .5s;
}

.fill-button:hover svg {
    fill: white;
}

.fill-button:after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    top: 0;
    background: linear-gradient(45deg, theme("colors.blue") 50%, theme("colors.white") 50%);
    z-index: -1;
}

.fill-button:hover,
.fill-button:hover:after {
    background-position: 0;
}

.fill-button, .fill-button:after {
    background-size: 300%;
    background-position: 100%;
    transition: .5s all ease;
}

body {
    font-variant-ligatures: none;
}

* {
    font-feature-settings: "ss03" 1;
}

/* relative before:w-full before:h-0.5 before:absolute before:bottom-0 before:left-0 before:transition-all before:duration-500 before:scale-x-0 hover:before:scale-x-100 before:bg-white */

.underline-link {
    position: relative;
}

.underline-link:before {
    content: "";
    width: 100%;
    height: 0.125rem;
    position: absolute;
    bottom: 0;
    left: 0;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    transition-duration: 500ms;
    transition-property: all;
    transform: scaleX(0);
    background-color: white;
}

.underline-link:hover:before {
    transform: scaleX(1);
}

/* Hide Calendar Icon In Chrome */
/* make the native arrow invisible and stretch it over the whole field so you can click anywhere
in the input field to trigger the native datepicker*/
input[type="date"]::-webkit-calendar-picker-indicator {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: auto;
    height: auto;
    color: transparent;
    background: transparent;
    cursor: pointer;
}

@font-face {
    font-family: 'GeneralSans';
    src: url('../public/fonts/GeneralSans-Extralight.woff2') format('woff2'),
    url('../public/fonts/GeneralSans-Extralight.woff') format('woff'),
    url('../public/fonts/GeneralSans-Extralight.ttf') format('truetype');
    font-weight: 200;
    font-display: swap;
    font-style: normal;
}


@font-face {
    font-family: 'GeneralSans';
    src: url('../public/fonts/GeneralSans-ExtralightItalic.woff2') format('woff2'),
    url('../public/fonts/GeneralSans-ExtralightItalic.woff') format('woff'),
    url('../public/fonts/GeneralSans-ExtralightItalic.ttf') format('truetype');
    font-weight: 200;
    font-display: swap;
    font-style: italic;
}

@font-face {
    font-family: 'GeneralSans';
    src: url('../public/fonts/GeneralSans-Light.woff2') format('woff2'),
    url('../public/fonts/GeneralSans-Light.woff') format('woff'),
    url('../public/fonts/GeneralSans-Light.ttf') format('truetype');
    font-weight: 300;
    font-display: swap;
    font-style: normal;
}


@font-face {
    font-family: 'GeneralSans';
    src: url('../public/fonts/GeneralSans-LightItalic.woff2') format('woff2'),
    url('../public/fonts/GeneralSans-LightItalic.woff') format('woff'),
    url('../public/fonts/GeneralSans-LightItalic.ttf') format('truetype');
    font-weight: 300;
    font-display: swap;
    font-style: italic;
}


@font-face {
    font-family: 'GeneralSans';
    src: url('../public/fonts/GeneralSans-Regular.woff2') format('woff2'),
    url('../public/fonts/GeneralSans-Regular.woff') format('woff'),
    url('../public/fonts/GeneralSans-Regular.ttf') format('truetype');
    font-weight: 400;
    font-display: swap;
    font-style: normal;
}


@font-face {
    font-family: 'GeneralSans';
    src: url('../public/fonts/GeneralSans-Italic.woff2') format('woff2'),
    url('../public/fonts/GeneralSans-Italic.woff') format('woff'),
    url('../public/fonts/GeneralSans-Italic.ttf') format('truetype');
    font-weight: 400;
    font-display: swap;
    font-style: italic;
}


@font-face {
    font-family: 'GeneralSans';
    src: url('../public/fonts/GeneralSans-Medium.woff2') format('woff2'),
    url('../public/fonts/GeneralSans-Medium.woff') format('woff'),
    url('../public/fonts/GeneralSans-Medium.ttf') format('truetype');
    font-weight: 500;
    font-display: swap;
    font-style: normal;
}


@font-face {
    font-family: 'GeneralSans';
    src: url('../public/fonts/GeneralSans-MediumItalic.woff2') format('woff2'),
    url('../public/fonts/GeneralSans-MediumItalic.woff') format('woff'),
    url('../public/fonts/GeneralSans-MediumItalic.ttf') format('truetype');
    font-weight: 500;
    font-display: swap;
    font-style: italic;
}


@font-face {
    font-family: 'GeneralSans';
    src: url('../public/fonts/GeneralSans-Bold.woff2') format('woff2'),
    url('../public/fonts/GeneralSans-Bold.woff') format('woff'),
    url('../public/fonts/GeneralSans-Bold.ttf') format('truetype');
    font-weight: 700;
    font-display: swap;
    font-style: normal;
}


@font-face {
    font-family: 'GeneralSans';
    src: url('../public/fonts/GeneralSans-BoldItalic.woff2') format('woff2'),
    url('../public/fonts/GeneralSans-BoldItalic.woff') format('woff'),
    url('../public/fonts/GeneralSans-BoldItalic.ttf') format('truetype');
    font-weight: 700;
    font-display: swap;
    font-style: italic;
}


@font-face {
    font-family: 'GeneralSans';
    src: url('../public/fonts/GeneralSans-Semibold.woff2') format('woff2'),
    url('../public/fonts/GeneralSans-Semibold.woff') format('woff'),
    url('../public/fonts/GeneralSans-Semibold.ttf') format('truetype');
    font-weight: 900;
    font-display: swap;
    font-style: normal;
}


@font-face {
    font-family: 'GeneralSans';
    src: url('../public/fonts/GeneralSans-SemiboldItalic.woff2') format('woff2'),
    url('../public/fonts/GeneralSans-SemiboldItalic.woff') format('woff'),
    url('../public/fonts/GeneralSans-SemiboldItalic.ttf') format('truetype');
    font-weight: 900;
    font-display: swap;
    font-style: italic;
}




@font-face {
    font-family: "FT88-Regular";
    src: url("../public/fonts/FT88-Regular.ttf");
}
